$(function(){

    $('.form-group input[type="text"]').change(function(){
        $(this).addClass('filled');
    });
    $('.form-group input[type="text"]').each(function(){
        //alert($(this).val());
        if($(this).val() != '') {
            $(this).addClass('filled');
        }
    });

    $('.form-group input').change(function(){
        $(this).addClass('filled');
    });
    $('.form-group input').each(function(){
        if($(this).val() != '') {
            $(this).addClass('filled');
        }
    });

    function formatDates() {
        $('span.date-start').each(function(){
            var date = $(this).html();
            var fmtDate = moment(date).format("MMM D, YYYY");
            $(this).html(fmtDate);
        });
        $('span.date-end').each(function(){
            var date = $(this).html();
            var fmtDate = moment(date).format("MMM D, YYYY");
            $(this).html(fmtDate);
        });
        $('span.date-created').each(function(){
            var date = $(this).html();
            var fmtDate = moment(date).format("MMM D, YYYY");
            $(this).html(fmtDate);
        });
        $('li.dates').each(function(){
            $(this).find('span').each(function(){
                var date = $(this).html();
                var fmtDate = moment(date).format("MMM D, YYYY");
                $(this).html(fmtDate);
            });
        });
    }


    $('span#btn-collapse').click(function() {
       $('#mission_search').animate({
           height: '80px'
       }, 300, 'linear', function() {
           $('#mission_search').css('overflow','hidden');
       });
        $('span#btn-expand').toggleClass('hide');
        $('span#btn-collapse').toggleClass('hide');
    });

    $('span#btn-expand').click(function() {
        $('#mission_search').animate({
            height: '800px'
        }, 300, 'linear', function() {
            $('#mission_search').css('overflow','scroll');
        });
        $('span#btn-expand').toggleClass('hide');
        $('span#btn-collapse').toggleClass('hide');
    });

    $('span#btn-list').click(function() {
        $('#mission_list').fadeOut(300, function(){
            $('ul.missions').addClass('list');
        });
        $('div.list-buttons span').removeClass('active');
        $(this).addClass('active');

        setTimeout(function(){
            $('#mission_list').fadeIn(300);
        }, 300);
    });

    $('span#btn-grid').click(function() {
        $('#mission_list').fadeOut(300, function(){
            $('ul.missions').removeClass('list');
        });
        $('div.list-buttons span').removeClass('active');
        $(this).addClass('active');
        setTimeout(function(){
            $('#mission_list').fadeIn(300);
        }, 300);
    });

    //$('#mission_search').animate({
    //    height: '80px'
    //}, 300, 'linear');


//    new Vue({
//        el: 'form.form',
//        data: {
//            msg      : 'hi!',
//            checked  : true,
//            picked   : 'one',
//            selected : 'two',
//            multiSelect: ['one', 'three']
//        }
//    });




    $(document).ready(function(){
        setTimeout(function(){
            formatDates();
        }, 800);

        //$('.datepicker').datepicker();
        $('input[type="month"]').datepicker();
    });
});